import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';

import store from '@/admin/store';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { createFaq, editFaq, getFaq } from '@/admin/api/faq';
import { FaqCardApiInterface, FaqCardModelInterface } from '@/interfaces/models/faq.interface';
import SiteModule from '@/admin/store/site';
import { getApiErrorMessage, showError } from '@/lib/utils/Utils';

export const MODULE_NAME = 'FaqEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FaqEntityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: FaqCardModelInterface;
    selectDefault = { id: 0, value: '' };

    constructor(module: FaqEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_CARD_ID(id: number) {
        this.cardId = id;
    }

    @Mutation
    SET_QUESTION(question: string) {
        this.model.question.value = question;
    }

    @Mutation
    SET_ANSWER(answer: string) {
        this.model.answer.value = answer;
    }

    @Action({ rawError: true })
    async initCardById(id: number) {
        this.SET_IS_LOADING(true);

        const result = await getFaq(id);
        await this.setInfo(result?.data.result.item);

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    setInfo(data: FaqCardApiInterface) {
        this.SET_CARD_ID(data.id);
        this.SET_QUESTION(data.question ?? '');
        this.SET_ANSWER(data.answer ?? '');
        this.SET_SORT_ORDER(data.sort);
    }

    @Action({ rawError: true })
    updateQuestion(value: string) {
        this.SET_QUESTION(value);
    }

    @Action({ rawError: true })
    updateAnswer(value: string) {
        this.SET_ANSWER(value);
    }

    @Action({ rawError: true })
    updateSortOrder(sort: string) {
        this.SET_SORT_ORDER(sort);
    }

    @Action({ rawError: true })
    validate() {
        const requiredFields = Object.keys(this.model).filter((key) => this.model[key]?.required === true);

        this.setValidateState({ index: 'question', field: this.model.question });
        this.setValidateState({ index: 'answer', field: this.model.answer });

        const fieldsWithError = requiredFields.filter((field) => this.model[field]?.error?.class === 'cp-error');

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareData() {
        const data: {
            question: string;
            answer: string;
            sort?: number;
        } = {
            question: this.model.question.value,
            answer: this.model.answer.value,
        };

        if (this.model.sort.value) {
            data['sort'] = +this.model.sort.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        if (!this.cardId) {
            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const data = await this.prepareData();
            const result = await editFaq(this.cardId, data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                this.initCardById(this.cardId);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendCreate(redirect: any = null) {
        const isError = await this.validate();

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const data = await this.prepareData();
            const result = await createFaq(data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    reset() {
        this.SET_IS_LOADING(false);
        this.SET_CARD_ID(0);
        this.SET_QUESTION('');
        this.SET_ANSWER('');
        this.SET_SORT_ORDER(0);

        SiteModule.SET_IS_BLOCK(false);

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.RESET_ERROR_BY_FIELD('question');
        this.RESET_ERROR_BY_FIELD('answer');
    }
}
export default getModule(FaqEntityModule);
