import { FaqCardModelInterface } from '@/interfaces/models/faq.interface';
import InputEntity from '@/lib/formFactory/inputEntity';

export default class EntityModel {
    model: FaqCardModelInterface;

    constructor() {
        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');

        const questionEntity = new InputEntity('question');
        questionEntity.setTitle('Вопрос');

        const answerEntity = new InputEntity('answer');
        answerEntity.setTitle('Ответ');

        this.model = {
            sort: sortEntity.entity[sortEntity.entityName],
            question: questionEntity.entity[questionEntity.entityName],
            answer: answerEntity.entity[answerEntity.entityName],
        };
    }
}
