import { makeDeleteRequest, makeFormRequest, makeGetRequest } from '@/api/helpers';
import API from '@/admin/config/api-routers';

export const getFaqList = async (page: number, sort: string, filter: string, items: number | string) => {
    const result = await makeGetRequest(`${API.faq.getList}?page=${page}&items=${items}${sort}${filter}`);

    return result.data.result.table;
};

export const getFaq = async (id: number) => {
    const result = await makeGetRequest(`${API.faq.card}${id}/`);

    return result;
};

export const editFaq = async (id: number, data: any) => {
    const result = await makeFormRequest(`${API.faq.card}${id}/`, data);

    return result;
};

export const createFaq = async (data: any) => {
    const result = await makeFormRequest(`${API.faq.create}`, data);

    return result;
};

export const deleteFaq = async (id: number) => {
    const result = await makeDeleteRequest(`${API.faq.card}/${id}`);

    return result;
};
