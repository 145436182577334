
import { Vue, Component } from 'vue-property-decorator';
import FaqEntity from '@/admin/store/content/faq/entity';

import InputDefault from '@/components/input/InputMain.vue';
import TextareaBlock from '@/components/form/Textarea.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import RemoveModal from '@/components/modules/removeModal.vue';
import { FaqCardModelInterface } from '@/interfaces/models/faq.interface';

@Component({
    components: {
        InputDefault,
        TextareaBlock,
        BtnDefault,
        BtnSubmit,
        RemoveModal,
    },
})
export default class FaqCard extends Vue {
    submitText = this.$route.name === 'content_faq_create' ? 'Создать' : 'Сохранить';

    get model(): FaqCardModelInterface {
        return FaqEntity.model;
    }

    updateQuestion(value: string): void {
        FaqEntity.updateQuestion(value);
    }

    updateAnswer(value: string): void {
        FaqEntity.updateAnswer(value);
    }

    updateSort(value: string): void {
        FaqEntity.updateSortOrder(value);
    }

    send(): void {
        if (this.$route.name === 'content_faq_create') {
            FaqEntity.sendCreate({ name: 'content_faq' });

            return;
        }

        FaqEntity.sendEdit();
    }

    init(): void {
        FaqEntity.initCardById(+this.$route.params.faqId);
    }

    cancel(): void {
        if (this.$route.name === 'content_faq_create') {
            this.returnBefore();
        }

        this.init();
    }

    returnBefore(): void {
        this.$router.push({ name: 'content_faq' });
    }

    created(): void {
        if (this.$route.name === 'content_faq_create') {
            return;
        }

        this.init();
    }

    beforeDestroy(): void {
        FaqEntity.reset();
    }
}
